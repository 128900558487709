<template>
  <div>
    <b-card>
      <b-form inline @submit="onSubmit" @reset="onReset">
        <h5 class="title">ส่วนค้นหาข้อมูล:</h5>
        <!-- บัญชีผู้ใช้ -->
        <b-input-group class="ml-1p">
          <b-input-group-prepend is-text>
            {{ customerRefUser }}
          </b-input-group-prepend>
          <!-- Input -->
          <b-form-input
            :placeholder="customerRefUser"
            v-model="form.customerRefUser"
          />
        </b-input-group>
        <!-- สถานะ -->
        <b-input-group class="ml-1p">
          <b-input-group-prepend is-text>
            {{ status }}
          </b-input-group-prepend>
          <!-- Input -->
          <b-form-select
            :placeholder="status"
            v-model="form.status"
            :options="optionsSelect"
          />
        </b-input-group>
        <b-button type="submit" variant="primary" class="ml-2p">
          {{ search }}
        </b-button>
        <b-button type="reset" variant="secondary" class="ml-1p">
          {{ reset }}
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  // Form
  BForm,
  BFormInput,
  // BFormGroup,
  BInputGroup,
  // Card
  BCard,
  // Button
  BButton,
  // Select
  BFormSelect,
  // Prepend
  BInputGroupPrepend,
} from "bootstrap-vue";

export default {
  components: {
    // Form
    BForm,
    BFormInput,
    // BFormGroup,
    BInputGroup,
    // Card
    BCard,
    // Button
    BButton,
    // Selected
    BFormSelect,
    // Prepend
    BInputGroupPrepend,
  },
  data() {
    return {
      // Form
      form: {
        customerRefUser: "",
        status: "",
      },
      customerRefUser: "บัญชีผู้ใช้",
      status: "สถานะ",
      search: "ค้นหา",
      reset: "ล้างค่า",
      searchBtn: "",
      optionsSelect: [{ text: "รายการใหม่", value: 'new' },{ text: "ข้อมูลถูกต้อง", value: 'completed'},{ text: "ไม่ผ่านการตรวจสอบ", value: 'rejected'}]
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$emit("SubmitData", this.form);
      // alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.customerRefUser = "";
      this.form.status = "";
    },
  },
};
</script>

<style scoped>
.ml-1p {
  margin-left: 1%;
}
.ml-2p {
  margin-left: 2%;
}
.title{
  display: contents;
  font-weight: bold;
}
</style>
